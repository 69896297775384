import { logMetricAsync } from './logging';

const { logError, logMetric } = require('./logging');
const { replaceUrlParam } = require('./url');
const { fetchWithTimeout } = require('./fetch');

let redirectTriggered = false;

export const getRedirectTriggered = () => redirectTriggered;

export const setRedirectTriggered = (val) => {
  redirectTriggered = val;
};

export const replaceDocumentLocation = (redirectURL) => {
  try {
    logMetricAsync('redirect_occurred');
  } catch (err) {
    logError('beacon_not_sent', 'Error calling navigator.sendBeacon');
  }
  /* This is only for app to app redirecting https://skyscanner.atlassian.net/browse/DTO-5573 for
     more information
     Do not use this parameter
  */
  // eslint-disable-next-line no-underscore-dangle
  if (window.__internal.isAppToAppEnabled && window.__internal.isApp) {
    document.location.href = redirectURL;
  } else {
    document.location.replace(redirectURL);
  }
};

export const parseCookieString = (cookieString) =>
  cookieString.split('; ').reduce((result, cookie) => {
    const [key, value] = cookie.split('=');
    return { ...result, [key]: value };
  }, {});

export const readCookie = (name, cookieString = document.cookie) => {
  const cookies = parseCookieString(cookieString);
  return cookies[name];
};

export const checkUtidState = (utidState) => {
  const stateString = `${utidState}`;
  if (stateString === '1' || stateString === '2') {
    return stateString;
  }
  return '0';
};

export const markAsRedirected = (redirectAction) => {
  setRedirectTriggered(true);
  setTimeout(() => {
    logError(
      'waited_6s_after_redirected',
      `Still on skippy 6s after redirecting to ${redirectAction.url}`,
    );
  }, 6000);
};

export const processPostRedirect = (redirectAction) => {
  const frm = document.createElement('FORM');
  // The page default is utf-8 and historically no charset
  // has been explicitly set. We'll support history for a while, When
  // receiving ut-8.
  if (
    typeof redirectAction.acceptCharset === 'string' &&
    redirectAction.acceptCharset.toLowerCase() !== 'utf-8'
  ) {
    frm.acceptCharset = redirectAction.acceptCharset;
  }
  frm.action = redirectAction.url;
  frm.method = 'POST';
  if (typeof redirectAction.enctype !== 'undefined') {
    frm.enctype = redirectAction.enctype;
    frm.encoding = redirectAction.enctype;
  }
  // We need to preserve submit method because it might get shadowed
  // by a form input field with same name, ie. <input name='submit' />.
  const originalSubmit = frm.submit;
  redirectAction.formData.forEach(([key, value]) => {
    const inputField = document.createElement('INPUT');
    inputField.type = 'text';
    inputField.id = key;
    inputField.name = key;
    inputField.value = value;
    frm.appendChild(inputField);
  });

  // Obviously, the <form> need not actually be visible on the page.
  frm.style.display = 'none';
  document.body.appendChild(frm);

  try {
    logMetricAsync('redirect_occurred');
  } catch (err) {
    logError('beacon_not_sent', 'Error calling navigator.sendBeacon');
  }
  originalSubmit.call(frm);
};

export const processRedirectAction = (redirectAction) => {
  switch (redirectAction.type) {
    case 'GET':
      if (document.location.replace) {
        replaceDocumentLocation(redirectAction.url);
      } else {
        logError(
          'redirect_fallback_used',
          'Redirected using HREF. Back button will be usable.',
          0,
          'info',
        );
        document.location.href = redirectAction.url;
      }
      markAsRedirected(redirectAction);
      break;
    case 'POST':
      processPostRedirect(redirectAction);
      markAsRedirected(redirectAction);
      break;
    default:
      logError(
        'unknown_redirect_action',
        `Unknown redirect action: '${redirectAction.type}'`,
      );
  }
};

// Called initially with context passed from server to page to this function
export const getUtidInfo = (context = {}) => {
  const utidCookie = readCookie('traveller_context');

  if (context.utid && context.utid.length > 0) {
    // If UTID found in GET params or headers server side.
    context.utid_state = checkUtidState(context.utid_state);
  } else if (utidCookie) {
    // Else if cookie found on client side, set it on context for redirection.
    context.utid = utidCookie;
    context.utid_state = checkUtidState(0);
  }
  return context;
};

export const canRedirect = (redirectDelay, timeout) => {
  const now = new Date();
  const elapsedTime = now - window.time_started;

  return (
    elapsedTime >= timeout ||
    (document.readyState !== 'loading' && elapsedTime >= redirectDelay)
  );
};

export const waitAndRedirect = (response, statusText, context) => {
  const { redirect_delay: redirectDelay, timeout } = context;
  if (!canRedirect(redirectDelay, timeout)) {
    // Wait 200ms and check again
    setTimeout(() => {
      waitAndRedirect(response, statusText, context);
    }, 200);
  } else {
    setTimeout(() => {
      if (
        statusText === 'timeout' ||
        statusText === 'error' ||
        statusText === 'parsererror'
      ) {
        logError(`skippy_${statusText}`, 'Skippy error', 0, 'error');
      } else if (typeof response !== 'object') {
        logError(
          'skippy_invresp',
          'Skippy API invalid response (not an object)',
          0,
          'error',
        );
      }
    }, 0);
    const badResponse =
      (response.status && response.status !== 200) ||
      response.status === 0 ||
      Object.keys(response.payload).length === 0;

    if (badResponse) {
      logMetric('fallback_url', response?.status);
    } else {
      context.redir_action = {
        ...context.redir_action,
        status: response.status,
        ...response.payload,
      };
    }
    // TODO: badResponse will break when calling processRedirectAction
    processRedirectAction(context.redir_action);
  }
};

export const getDeeplinkAndRedirect = async (context = {}) => {
  const { timeout, utid, utid_state: utidState, virtualSsabCookie } = context;
  let { callback_url: callbackURL } = context;

  if (utid) {
    callbackURL = replaceUrlParam(callbackURL, 'utid', utid);
    callbackURL = replaceUrlParam(callbackURL, 'utid_state', utidState);
  }

  logMetric('skippy_api_call');
  try {
    const response = await fetchWithTimeout(
      callbackURL,
      {
        headers: {
          Accept: 'application/json, text/javascript, */*; q=0.01',
          'Content-Type': 'application/json',
          'X-Skyscanner-Experiments': virtualSsabCookie || '',
        },
      },
      timeout,
    );
    const { headers, status, statusText } = response || {};
    if (response) {
      try {
        const payload = await response.json();
        // TODO: Review status conditions as the below code is not being called in production
        // If PX return 403 - forbidden, then we need to present a Captcha page to the user
        if (
          status &&
          status === 403 &&
          headers.get('content-type') === 'application/json'
        ) {
          logError(
            'skippy_403',
            'Have a 403 response from skippy',
            186,
            'info',
          );
          // redirect_to will point to the captcha page.
          // Using window.location for cross browser safety https://www.w3.org/TR/html/browsers.html#dom-location
          if (payload.reason === 'blocked' && payload.redirect_to) {
            window.location = payload.redirect_to;
            logError('captcha_page', 'Redirected to captcha page', 0, 'info');
          }
        }
        waitAndRedirect({ status, payload }, statusText, context);
      } catch (error) {
        logError('fetch_with_timeout_json_failure', error.message, 0, 'info');
        waitAndRedirect({ status }, 'error', context);
      }
    } else {
      logError(
        'fetch_with_timeout_null_failure',
        `timout=${timeout}`,
        0,
        'info',
      );
    }
  } catch (error) {
    logError('fetch_with_timeout_failure', error.message, 0, 'info');
    // TODO: investigate why we have error as a string in one case and error instance in another
    waitAndRedirect({ status: 0 }, error, context);
  }
};

export const initiateRedirect = async (context) => {
  // Call getUtidInfo to update context and wait for it to finish
  const updatedContext = getUtidInfo(context);

  // Call getDeeplinkAndRedirect and wait for the promise to resolve
  await getDeeplinkAndRedirect(updatedContext);
};
