export const namespace = 'redirects-ui';

// task
export const TASK_TRANSPORT = 'transport_deeplink';
export const TASK_FLIGHTS = 'flights_deeplink';
export const TASK_CARHIRE = 'carhire_deeplink';
export const TASK_HOTEL = 'hotel_deeplink';
export const TASK_AIRPORT_TRANSFER = 'airport_transfer_deeplink';

export const TRACKING = {
  SCHEMAS: {
    EXPERIMENT_CHOKEPOINT_SCHEMA: 'experiment_choke_point.ExperimentChokePoint',
    SMART_METRIC_SCHEMA: 'experiment_smart_metrics.SmartMetric',
  },
  MESSAGES: {
    EXPERIMENT_CHOKEPOINT: 'ExperimentChokePoint',
    SMART_METRIC: 'SmartMetric',
  },
};
