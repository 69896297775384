const TIMEOUT_ERROR_LABEL = 'timeout';

export const fetchWithTimeout = (url, options, timeout = 10000) =>
  new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(TIMEOUT_ERROR_LABEL);
    }, timeout);

    window
      .fetch(url, {
        ...options,
      })
      .then((value) => {
        clearTimeout(timer);
        resolve(value);
      })
      .catch((reason) => {
        clearTimeout(timer);
        reject(reason);
      });
  });
