const DEEPLINK_MAP = {
  CARHIRE_DEEPLINK: 'carhire_deeplink',
  HOTEL_DEEPLINK: 'hotel_deeplink',
  TRANSPORT_DEEPLINK: 'transport_deeplink',
};
const VERTICAL_MAP = {
  TRANSPORT: 'TRANSPORT',
  HOTEL: 'HOTEL',
  CAR_HIRE: 'CAR_HIRE',
};

export const getVertical = (callbackUrl) => {
  if (callbackUrl.includes(DEEPLINK_MAP.TRANSPORT_DEEPLINK)) {
    return VERTICAL_MAP.TRANSPORT;
  }
  if (callbackUrl.includes(DEEPLINK_MAP.HOTEL_DEEPLINK)) {
    return VERTICAL_MAP.HOTEL;
  }
  if (callbackUrl.includes(DEEPLINK_MAP.CARHIRE_DEEPLINK)) {
    return VERTICAL_MAP.CAR_HIRE;
  }
  return '';
};
