import { GLOBAL } from 'saddlebag-browser';

import { logError } from './logging';

const $document = GLOBAL.getDocument();

export const replaceUrlParam = (rawUrl, paramName, paramValue) => {
  try {
    const url = new URL(rawUrl, $document.location.href);
    url.searchParams.set(paramName, paramValue);
    return url.toString();
  } catch (e) {
    logError(
      'invalid_url',
      `Unable to set url param ${paramName}=${paramValue} in ${rawUrl}`,
    );
    return rawUrl;
  }
};
