import { MiniEventsTracking } from 'saddlebag-user-tracking-events';

import { TRACKING } from '../constants';

export const miniEventsTracking = new MiniEventsTracking(
  'public',
  'redirects-ui',
);

export const trackExperimentChokePoint = (name) =>
  miniEventsTracking.track(
    TRACKING.MESSAGES.EXPERIMENT_CHOKEPOINT,
    TRACKING.SCHEMAS.EXPERIMENT_CHOKEPOINT_SCHEMA,
    {
      name,
    },
  );

export const trackExperimentSmartMetric = (name) =>
  miniEventsTracking.track(
    TRACKING.MESSAGES.SMART_METRIC,
    TRACKING.SCHEMAS.SMART_METRIC_SCHEMA,
    {
      name,
    },
  );
