/* eslint-disable no-underscore-dangle */
import { GLOBAL } from 'saddlebag-browser';

const { fetchWithTimeout } = require('./fetch');
const { getVertical } = require('./vertical');

let sentErrorsCount = 0;
const seenErrors = [];
const logSendThreshold = 10;

const buildLog = (level, eventType, status) => ({
  vertical: getVertical(window.__internal.context.callback_url),
  level: level || 'info',
  eventType,
  status,
  url: window.__internal.context.callback_url,
  redirect_id: window.__internal.context.redirect_id || undefined,
});

export const logError = (
  errorType,
  message,
  lineNumber,
  level = 'error',
  source,
) => {
  const data = {
    vertical: getVertical(window.__internal.context.callback_url),
    message: message || undefined,
    level,
    url: window.location.href,
    source: source || undefined,
    line_number: lineNumber || undefined,
    redirect_id: window.__internal.context.redirect_id || undefined,
  };

  fetchWithTimeout(`/skippy_logging/${errorType}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const logMetric = (eventType, status = undefined, level = 'info') => {
  const data = buildLog(level, eventType, status);

  fetchWithTimeout('/skippy_logging/redirects_ui_metric', {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const logClientError = (
  message,
  source,
  lineno,
  eventType = 'client_error',
) => {
  const seenKey = `${message}${source}${lineno}`;
  if (seenErrors.indexOf(seenKey) >= 0) {
    return;
  }
  seenErrors.push(seenKey);
  if (sentErrorsCount < logSendThreshold) {
    logError(eventType, message, lineno, 'error', source);
    sentErrorsCount += 1;
  }
};

export const logMetricAsync = (
  eventType,
  status = undefined,
  level = 'info',
) => {
  const data = buildLog(level, eventType, status);
  const blob = new Blob([JSON.stringify(data)], {
    type: 'application/json',
  });
  const success = GLOBAL.getNavigator().sendBeacon(
    '/skippy_logging/redirects_ui_metric',
    blob,
  );
  if (!success) {
    logError('beacon_not_queued', 'Error with beacon');
  }
};
