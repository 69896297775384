/* eslint-disable camelcase */
/* eslint-disable func-names */
/* eslint-disable consistent-return */

/* eslint-disable no-underscore-dangle */

/* eslint-disable no-var */

const {
  getSourceDomain,
  isInternalSourceError,
} = require('../../../src/utils/errorHandling');
const {
  logClientError: _logClientError,
  logError: _logError,
  logMetric: _logMetric,
  logMetricAsync: _logMetricAsync,
} = require('../../../src/utils/logging');
const { replaceUrlParam: _replaceUrlParam } = require('../../../src/utils/url');
const { canRedirect: _canRedirect } = require('../../../src/utils/redirect');
const {
  getDeeplinkAndRedirect: _getDeeplinkAndRedirect,
  getRedirectTriggered,
  getUtidInfo: _getUtidInfo,
  initiateRedirect: _initiateRedirect,
  processRedirectAction: _processRedirectAction,
  waitAndRedirect: _waitAndRedirect,
} = require('../../../src/utils/redirect');
const {
  trackExperimentChokePoint: _trackExperimentChokePoint,
} = require('../../../src/utils/minievent');

window.skyscanner = {};

/**
 * Static element object.  This is an example of how we could load multiple
 * elements on the redirect page, and take action depending on how the elements
 * load.
 *
 * This object could sit in an external .js file, so it could be compressed
 * and cached by the standard mechanisms for the whole site.
 */
window.skyscanner.element = (function () {
  var _isCrossDomainError = function (message) {
    const crossOriginError = 'Script error';
    return [crossOriginError, `${crossOriginError}.`].indexOf(message) >= 0;
  };

  var _isExternalSourceError = function (url) {
    const errorWhitelist = [
      'www.skyscanner',
      'js.skyscnr.com',
      'css.skyscnr.com',
      'images.skyscnr.com',
      'skyscannertools.net',
    ];

    return !errorWhitelist.some((whitelist) => url.indexOf(whitelist) > -1);
  };

  window.onerror = function (msg, url, line_number) {
    // Don't log cross-domain script errors
    // Don't log errors which don't come from our scripts
    if (_isCrossDomainError(msg) || _isExternalSourceError(url)) return;
    _logClientError(msg, url, line_number);
  };

  window.onunhandledrejection = function (rejection) {
    const { fileName, lineNumber } = rejection.reason || {};
    /*
        https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
        window.onunhandledrejection forwards on the value given to Promise.reject. Compared to window.onerror this is less dependable to work with, as there is nothing to enforce specific fields will exist within the rejection reason.
        */
    if (!rejection.reason) {
      return _logClientError(
        'Unhandled promise rejection with undefined reason',
        fileName,
        lineNumber,
        'unidentified_error',
      );
    }

    try {
      const sourceDomain = getSourceDomain(rejection.reason.stack);

      if (isInternalSourceError(sourceDomain)) {
        _logClientError(
          `unhandled rejection: ${rejection.reason} stack: ${rejection.reason.stack}`,
          fileName,
          lineNumber,
          'client_error',
        );
      } else {
        _logClientError(
          `Unhandled promise rejection from ${sourceDomain} with reason: ${rejection.reason} stack: ${rejection.reason.stack}`,
          fileName,
          lineNumber,
          'external_error',
        );
      }
    } catch (e) {
      _logClientError(
        `error ${e.message} processing unhandled rejection with rejection reason: ${rejection.reason}. stack: ${rejection.reason.stack}`,
        fileName,
        lineNumber,
        'unidentified_error',
      );
    }
  };

  window.onpageshow = function (event) {
    if (event.persisted) {
      _logError(
        'cache_bf',
        'Page shown from the Back-Forward cache',
        0,
        'info',
      );
    }
  };

  setTimeout(() => {
    const errorMessage = getRedirectTriggered()
      ? 'waited_10s_after_load_redirect_triggered'
      : 'waited_10s_after_load';
    _logError(errorMessage, errorMessage);
  }, 10000);

  // Public interface for the skyscanner.element object.
  return {
    initiateRedirect: _initiateRedirect,
    processRedirectAction: _processRedirectAction,
    waitAndRedirect: _waitAndRedirect,
    canRedirect: _canRedirect,
    logError: _logError,
    logMetric: _logMetric,
    logMetricAsync: _logMetricAsync,
    getUtidInfo: _getUtidInfo,
    getDeeplinkAndRedirect: _getDeeplinkAndRedirect,
    replaceUrlParam: _replaceUrlParam,
    trackExperimentChokePoint: _trackExperimentChokePoint,
  };
})();
