export const isInternalSourceError = (source) => {
  const errorAllowList = [
    'skyscanner',
    'www.skyscanner',
    'js.skyscnr.com',
    'css.skyscnr.com',
    'images.skyscnr.com',
    'skyscannertools.net',
  ];

  return errorAllowList.some(
    (allowedEntry) => source && source.includes(allowedEntry),
  );
};

export const getSourceDomain = (stackTrace) => {
  if (!stackTrace) {
    return undefined;
  }
  const allFrames = stackTrace.trim().split('\n');
  const lastFrame = allFrames[allFrames.length - 1];
  const secondLastFrame = allFrames[allFrames.length - 2];
  const stackInitiatorFrame = lastFrame.includes('nrWrapper')
    ? secondLastFrame
    : lastFrame;

  // The first URL reference within that frame _should_ be the source URL of the error. We take that risk trade off.
  const sourceDomain = stackInitiatorFrame.match(
    /https?:\/\/([A-z0-9.-]+)/,
  )?.[1];

  return sourceDomain;
};
